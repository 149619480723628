export const ENVIRONMENT_API_PATH = process.env.REACT_APP_API_PATH;
export const ENVIRONMENT_FILE_UPLOAD_LIMIT = process.env.REACT_APP_FILE_UPLOAD_LIMIT;
export const ENVIRONMENT_TINY_MCE_API_KEY = process.env.REACT_APP_TINY_MCE_API_KEY;
export const ENVIRONMENT_ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_APP_ID ?? "";
export const ENVIRONMENT_ALGOLIA_SEARCH_API_KEY = process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY ?? "";
export const ENVIRONMENT_ALGOLIA_INDEX_NAME = process.env.REACT_APP_ALGOLIA_INDEX_NAME ?? "";
export const ENVIRONMENT_SHOW_LOGGING = process.env.REACT_APP_SHOW_LOGGING === "true" ? true : false;

export const getFileUploadLimit = () => {
  return ENVIRONMENT_FILE_UPLOAD_LIMIT ? parseInt(ENVIRONMENT_FILE_UPLOAD_LIMIT) : 15000000;
};
