import React from "react";
import { SearchFilterObjectTypeEnum } from "../../../../interfaces/algolia/ISearchResultHit";
import { ClearRefinements } from "react-instantsearch-dom";
import PublishStateRefinement from "./SearchFilterRefinement/PublishStateRefinement";
import PlaceRefinement from "./SearchFilterRefinement/PlaceRefinement";
import "./SearchFilter.scss";

interface SearchFilterProps {
  showFilterOnObjectTypes?: Array<SearchFilterObjectTypeEnum>;
  objectType?: SearchFilterObjectTypeEnum;
}

const SearchFilter: React.FunctionComponent<SearchFilterProps> = (props) => {

  return (
    <div className={`SearchFilter`}>
      <div className="row">
        <div className="col">
          <div className="row">

            <div className="col-lg-2">
              <PublishStateRefinement></PublishStateRefinement>
            </div>

            {
            // The check on objectType is a work-around since filter selectors were visible even when there no available options
            props.objectType === SearchFilterObjectTypeEnum.Person &&
            <div className="col">
              <PlaceRefinement title="Bosted" fieldName="placeOfResidence"></PlaceRefinement>

              <PlaceRefinement title="Fødested" fieldName="placeOfBirth"></PlaceRefinement>
            </div>
            }
          </div>
        </div>
      </div>

      <div className="text-right">
        <ClearRefinements
          translations={{
            reset: "Nullstill filter",
          }}
        />
      </div>
    </div>
  );
};

export default SearchFilter;
