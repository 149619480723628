import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { FiKey, FiTrash2 } from "react-icons/fi";
import { DELETE_USER_MUTATION, ASSIGN_USER_ROLE_MUTATION } from "../../../graphql/mutations";
import { GET_USERS_QUERY } from "../../../graphql/queries";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import { IDeleteUserMutationInput, IDeleteUserMutationResult } from "../../../interfaces/mutations/IDeleteUserMutation";
import { IGetUsersQueryResult } from "../../../interfaces/queries/IGetUsersQuery";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";
import { IAssignUserRoleMutationResult, IAssignUserRoleMutationInput } from "../../../interfaces/mutations/IAssignUserRoleMutation";
import { UserRolesEnum } from "../../../interfaces/domain/IUser";
import { userIsAdmin, userIsModerator } from "../../../helpers/userHelpers";
import { useAuth } from "../../../lib/hooks/useAuth";

const UsersList: React.FunctionComponent = (props) => {
  const { isAdmin } = useAuth();
  const { loading, error, data } = useQuery<IGetUsersQueryResult>(GET_USERS_QUERY);
  const [deleteUser] = useMutation<IDeleteUserMutationResult, IDeleteUserMutationInput>(DELETE_USER_MUTATION);
  const [assignUserRole] = useMutation<IAssignUserRoleMutationResult, IAssignUserRoleMutationInput>(ASSIGN_USER_ROLE_MUTATION);

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.users || !data.users.length) return <DataNotFound />;

  const onDeleteUser = async (id: string) => {
    const deleteConfirmed = window.confirm(`Er du sikker på at du vil slette brukeren fra systemet?`);

    if (deleteConfirmed) {
      await deleteUser({
        variables: {
          input: {
            userId: id,
          },
        },
        refetchQueries: [{ query: GET_USERS_QUERY }],
      })
        .then((result) => {
          if (result.data && mutationIsSuccessful(result.data.deleteUser)) {
            handleMutationSuccess(result.data, "Sletting vellykket");
          } else {
            handleMutationError(undefined, "Kunne ikke slette");
          }
        })
        .catch((error) => {
          handleMutationError(error, "Kunne ikke slette");
        });
    }
  };

  const onAssignUserRole = async (id: string, role: UserRolesEnum) => {
    const assignConfirmed = window.confirm(`Er du sikker?`);

    if (assignConfirmed) {
      await assignUserRole({
        variables: {
          input: {
            userId: id,
            role: role,
          },
        },
        refetchQueries: [{ query: GET_USERS_QUERY }],
      })
        .then((result) => {
          if (result.data && mutationIsSuccessful(result.data.assignUserRole)) {
            handleMutationSuccess(result.data, "Tildeling vellykket");
          } else {
            handleMutationError(undefined, "Kunne ikke tildele");
          }
        })
        .catch((error) => {
          handleMutationError(error, "Kunne ikke tildele");
        });
    }
  };

  return (
    <div className="UsersList">
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Navn</th>
            <th scope="col">E-post</th>
            <th scope="col">Brukernavn</th>
            <th scope="col">Roller</th>
            <th scope="col"></th>
          </tr>
        </thead>

        <tbody>
          {data.users.map((user) => {
            const toggleAdminLabel = userIsAdmin(user) ? "Fjern administrator" : "Sett som administrator";
            const toggleModeratorLabel = userIsModerator(user) ? "Fjern moderator" : "Sett som moderator";

            return (
              <tr key={user.id}>
                <td>{user.fullName}</td>
                <td>{user.email}</td>
                <td>{user.userName}</td>
                <td>
                  {user.roles.map((role) => {
                    return (
                      <span key={role} className="badge badge-primary mr-1">
                        {role}
                      </span>
                    );
                  })}
                </td>
                <td className="text-right">
                  {isAdmin() && (
                    <DropdownButton
                      size="sm"
                      variant="secondary"
                      alignRight
                      id="dropdown-basic-button"
                      className="inline-block"
                      title="Valg">
                      <Dropdown.Item
                        onClick={() => {
                          onAssignUserRole(user.id, UserRolesEnum.ADMIN);
                        }}>
                        <FiKey /> {toggleAdminLabel}
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => {
                          onAssignUserRole(user.id, UserRolesEnum.MODERATOR);
                        }}>
                        <FiKey /> {toggleModeratorLabel}
                      </Dropdown.Item>

                      <Dropdown.Divider></Dropdown.Divider>

                      <Dropdown.Item
                        disabled={userIsAdmin(user)}
                        className={userIsAdmin(user) ? "" : "text-danger"}
                        onClick={() => {
                          onDeleteUser(user.id);
                        }}>
                        <FiTrash2 /> Slett
                      </Dropdown.Item>
                    </DropdownButton>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default UsersList;
