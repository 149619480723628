export interface IUser {
  id: string;
  email: string;
  userName: string;
  fullName: string;
  roles: Array<string>;
}

export interface IAuthenticatedUser extends IUser {
  expiry: number;
}

export enum UserRolesEnum {
  MODERATOR = "moderator",
  ADMIN = "admin"
}

export enum TokenClaims {
  ROLE = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role",
  NAMEIDENTIFIER = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier",
  NAME = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
}

export interface JwtToken {
  sub: string;
  jti: string;
  [TokenClaims.NAMEIDENTIFIER]: string;
  [TokenClaims.ROLE]: Array<string> | string | undefined;
  [TokenClaims.NAME]: string;
  nbf: number;
  exp: number;
  iss: string;
  aud: string;
}
