import { useForm, Controller } from "react-hook-form";
import WYSIWYGEditor from "../../_common/Form/WYSIWYGEditor/WYSIWYGEditor";
import { IAssignment } from "../../../interfaces/domain/IAssignment";
import { IAssignmentForm } from "../../../interfaces/forms/IAssignmentForm";
import { assignmentSchema } from "../../../helpers/validators/assignmentSchema";
import { Prompt } from "react-router-dom";
import FormSubTitle from "../../_common/Form/FormSubTitle/FormSubTitle";
import FieldError from "../../_common/Form/FieldError/FieldError";
import { useMutation } from "@apollo/client";
import { UPDATE_ASSIGNMENT_MUTATION } from "../../../graphql/mutations";
import { IUpdateAssignmentMutationInput, IUpdateAssignmentMutationResult } from "../../../interfaces/mutations/IUpdateAssignmentMutation";
import { GET_ASSIGNMENT_QUERY } from "../../../graphql/queries";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import { mapAttachmentToSelectOption } from "../../../helpers/selectHelpers";
import Select from "react-select";

interface IEditAssignmentFormProps {
  assignment: IAssignment;
}

const EditAssignmentForm: React.FunctionComponent<IEditAssignmentFormProps> = (props) => {
  const { register, handleSubmit, errors, formState, getValues, setValue, control } = useForm<IAssignmentForm>({
    validationSchema: assignmentSchema,
    mode: "onChange",
    defaultValues: {
      title: props.assignment.title,
      displayOrder: props.assignment.displayOrder,
      description: props.assignment.description,
      hint: props.assignment.hint,
      mainImage: props.assignment.mainImage ? mapAttachmentToSelectOption(props.assignment.mainImage) : undefined,
      teachingSubjectId: props.assignment.teachingSubjectId,
    },
  });

  const [updateAssignment] = useMutation<IUpdateAssignmentMutationResult, IUpdateAssignmentMutationInput>(UPDATE_ASSIGNMENT_MUTATION);
  const onSubmit = handleSubmit(async (formValues) => {
    await updateAssignment({
      variables: {
        input: {
          id: props.assignment.id,
          title: formValues.title,
          displayOrder: formValues.displayOrder,
          description: formValues.description,
          hint: formValues.hint,
          mainImageId: formValues.mainImage && formValues.mainImage.value ? Number(formValues.mainImage.value) : undefined,
          teachingSubjectId: props.assignment.teachingSubjectId,
        },
      },
      refetchQueries: [{ query: GET_ASSIGNMENT_QUERY, variables: { filter: { id: props.assignment.id } } }],
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.updateAssignment)) {
          handleMutationSuccess(result.data);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <Prompt
        when={formState.dirty && !formState.isSubmitted}
        message="Du har endringer som ikke er lagret. Er du sikker på du vil fortsette? Endringene vil gå tapt hvis du fortsetter uten å lagre.
        Trykk OK for å gå videre uten å lagre, eller trykk Avbryt for å gå tilbake."
      />
      <div className="row">
        <div className="col-lg-8">
          <div className="card shadow-sm">
            <div className="card-body">
              <div className="form-group">
                <label className="required-field" htmlFor="title">
                  Tittel
                </label>
                <input type="text" name="title" ref={register} className={`form-control ${errors.title ? "is-invalid" : "valid"}`} />

                <FieldError error={errors.title}></FieldError>
              </div>

              <div className="form-group">
                <label htmlFor="displayOrder">Rekkefølge</label>
                <input
                  type="number"
                  name="displayOrder"
                  ref={register}
                  className={`form-control ${errors.displayOrder ? "is-invalid" : "valid"}`}
                />

                <FieldError error={errors.displayOrder}></FieldError>
              </div>

              <WYSIWYGEditor
                fieldName="description"
                label="Innhold"
                register={register}
                errors={errors}
                setValue={setValue}
                currentValue={getValues().description}
              />

              <div className="form-group">
                <WYSIWYGEditor
                  fieldName="hint"
                  label="Hint"
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  currentValue={getValues().hint}
                />

                <FieldError error={errors.hint}></FieldError>
              </div>
            </div>

            <div className="card-footer">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-primary">
                  {formState.isSubmitting ? (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span> Lagrer...</span>
                    </>
                  ) : (
                    <span>Lagre</span>
                  )}
                </button>

                <div>
                  <small className="text-muted">Innholdet vil bli publisert</small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4">
          <div className="card shadow-sm">
            <div className="card-body">
              <FormSubTitle>Hovedbilde</FormSubTitle>
              <div className="form-row">
                <div className="col-md-12">
                  {props.assignment.mainImage || props.assignment.availableImageAttachments.length > 0 ? (
                    <div className="form-group">
                      <Controller
                        as={Select}
                        control={control}
                        name="mainImage"
                        value={getValues().mainImage}
                        options={props.assignment.availableImageAttachments.map((attachment) => mapAttachmentToSelectOption(attachment))}
                        placeholder="Velg..."
                        isClearable
                      />
                    </div>
                  ) : (
                    <span className="text-muted">Du må først legge til en referanse med et bildevedlegg.</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditAssignmentForm;
