import { useMutation, useQuery } from "@apollo/client";
import { GET_TEACHING_SUBJECT_QUERY } from "../../../graphql/queries";
import { IGetTeachingSubjectQueryFilter, IGetTeachingSubjectQueryResult } from "../../../interfaces/queries/IGetTeachingSubjectQuery";
import { useAuth } from "../../../lib/hooks/useAuth";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";
import { Button, ButtonToolbar, Dropdown, DropdownButton } from "react-bootstrap";
import {
  IDeleteTeachingSubjectMutationInput,
  IDeleteTeachingSubjectMutationResult,
} from "../../../interfaces/mutations/IDeleteTeachingSubjectMutation";
import { DELETE_TEACHING_SUBJECT_MUTATION, TOGGLE_PUBLISH_TEACHING_SUBJECT_MUTATION } from "../../../graphql/mutations";
import { useHistory } from "react-router-dom";
import { GET_TEACHING_CATEGORY_ROUTE } from "../../../lib/Router/routes";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import { FiTrash2 } from "react-icons/fi";
import EditTeachingSubjectFormContainer from "./EditTeachingSubjectFormContainer";
import {
  ITogglePublishTeachingSubjectMutationInput,
  ITogglePublishTeachingSubjectMutationResult,
} from "../../../interfaces/mutations/ITogglePublishTeachingSubjectMutation";

interface IEditTeachingSubjectProps {
  id: number;
}

const EditTeachingSubject: React.FunctionComponent<IEditTeachingSubjectProps> = (props) => {
  const { isAdmin } = useAuth();
  const history = useHistory();

  const { loading, error, data } = useQuery<IGetTeachingSubjectQueryResult, IGetTeachingSubjectQueryFilter>(GET_TEACHING_SUBJECT_QUERY, {
    variables: { filter: { id: props.id } },
  });

  const [deleteTeachingSubject] = useMutation<IDeleteTeachingSubjectMutationResult, IDeleteTeachingSubjectMutationInput>(
    DELETE_TEACHING_SUBJECT_MUTATION
  );
  const [togglePublishTeachingSubject, { loading: togglePublishTeachingSubjectLoading }] = useMutation<
    ITogglePublishTeachingSubjectMutationResult,
    ITogglePublishTeachingSubjectMutationInput
  >(TOGGLE_PUBLISH_TEACHING_SUBJECT_MUTATION);

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.teachingSubject) return <DataNotFound />;

  const onDeleteTeachingSubject = async (teachingSubjectId: number) => {
    const deleteConfirmed = window.confirm(`Er du sikker på at du vil slette faget?`);

    if (deleteConfirmed) {
      await deleteTeachingSubject({
        variables: {
          input: {
            id: teachingSubjectId,
          },
        },
      })
        .then((result) => {
          if (result.data && mutationIsSuccessful(result.data.deleteTeachingSubject)) {
            handleMutationSuccess(result.data);

            history.push(GET_TEACHING_CATEGORY_ROUTE(data.teachingSubject.teachingCategoryId));
          } else {
            handleMutationError();
          }
        })
        .catch((error) => {
          handleMutationError(error);
        });
    }
  };

  const onTogglePublishTeachingSubject = async (teachingSubjectId: number, publish: boolean) => {
    await togglePublishTeachingSubject({
      variables: {
        input: {
          id: teachingSubjectId,
          publish: publish,
        },
      },
      refetchQueries: [{ query: GET_TEACHING_SUBJECT_QUERY, variables: { filter: { id: teachingSubjectId } } }],
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.togglePublishTeachingSubject)) {
          handleMutationSuccess(result.data);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3">
        <h1 className="h1">{data.teachingSubject.title}</h1>

        <div>
          {isAdmin() && (
            <ButtonToolbar className="d-flex justify-content-end">
              {data.teachingSubject.publishStatus.isPublished ? (
                <Button
                  variant="warning"
                  size="sm"
                  className="mr-1"
                  disabled={togglePublishTeachingSubjectLoading}
                  onClick={(event: any) => {
                    event.preventDefault();
                    onTogglePublishTeachingSubject(data.teachingSubject.id, !data.teachingSubject.publishStatus.isPublished);
                  }}>
                  {togglePublishTeachingSubjectLoading ? (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span> Avpubliserer...</span>
                    </>
                  ) : (
                    <span> Avpubliser</span>
                  )}
                </Button>
              ) : (
                <Button
                  variant="success"
                  size="sm"
                  className="mr-1"
                  disabled={togglePublishTeachingSubjectLoading}
                  onClick={(event: any) => {
                    event.preventDefault();
                    onTogglePublishTeachingSubject(data.teachingSubject.id, !data.teachingSubject.publishStatus.isPublished);
                  }}>
                  {togglePublishTeachingSubjectLoading ? (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span> Publiserer...</span>
                    </>
                  ) : (
                    <span> Publiser</span>
                  )}
                </Button>
              )}
              <DropdownButton size="sm" variant="secondary" alignRight id="dropdown-basic-button" title="Flere valg">
                <Dropdown.Item
                  className="text-danger"
                  eventKey="1"
                  onClick={() => {
                    onDeleteTeachingSubject(data.teachingSubject.id);
                  }}>
                  <FiTrash2 /> Slett oppgave
                </Dropdown.Item>
              </DropdownButton>
            </ButtonToolbar>
          )}
        </div>
      </div>

      <EditTeachingSubjectFormContainer teachingSubject={data.teachingSubject} />
    </div>
  );
};

export default EditTeachingSubject;
