import React from "react";
import { ToastContainer } from "react-toastify";
import ApolloWrapper from "../lib/ApolloWrapper";
import GlobalStylesWrapper from "../lib/GlobalStylesWrapper";
import Router from "../lib/Router/Router";
import { AuthProvider } from "../lib/hooks/useAuth";

const App: React.FunctionComponent = props => {
  return (
    <AuthProvider>
      <ApolloWrapper>
        <GlobalStylesWrapper>
          <Router></Router>
          <ToastContainer position="bottom-left" autoClose={5000} hideProgressBar closeOnClick draggable pauseOnHover />
        </GlobalStylesWrapper>
      </ApolloWrapper>
    </AuthProvider>
  );
};

export default App;
