import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Captivities from "../../components/Captivity/Captivities/Captivities";
import Events from "../../components/Event/Events/Events";
import EditHistoryForm from "../../components/History/EditHistory/EditHistoryForm/EditHistoryForm";
import HistoryReferences from "../../components/History/EditHistory/HistoryReferences";
import HistoryPrisonCamps from "../../components/History/EditHistory/HistoryPrisonCamps";
import HistoryPersons from "../../components/History/EditHistory/HistoryPersons";
import EditPersonForm from "../../components/Person/EditPerson/EditPersonForm/EditPersonForm";
import PersonReferences from "../../components/Person/EditPerson/PersonReferences";
import EditPrisonCampForm from "../../components/PrisonCamp/EditPrisonCamp/EditPrisonCampForm";
import PrisonCampReferences from "../../components/PrisonCamp/EditPrisonCamp/PrisonCampReferences";
import PrisonCampPersons from "../../components/PrisonCamp/EditPrisonCamp/PrisonCampPersons";
import EditReferenceFormContainer from "../../components/Reference/EditReference/EditReferenceFormContainer";
import ReferencePersons from "../../components/Reference/EditReference/ReferencePersons";
import PrisonCamps from '../../components/PrisonCamp/PrisonCamps/PrisonCamps';
import Histories from '../../components/History/Histories/Histories';
import Undervisning from '../../components/TeachingModule/EditTeachingModule/EditTeachingModule';
import Layout from "../../components/_common/Layouts/Layout/Layout";
import LayoutPublic from "../../components/_common/Layouts/LayoutPublic/LayoutPublic";
import PageNotFound from "../../components/_common/PageNotFound/PageNotFound";
import { IHistory } from "../../interfaces/domain/IHistory";
import { IPerson } from "../../interfaces/domain/IPerson";
import { IPrisonCamp } from "../../interfaces/domain/IPrisonCamp";
import { IQuestion } from "../../interfaces/domain/IQuestion";
import { IAssignment } from "../../interfaces/domain/IAssignment";
import { IReference } from "../../interfaces/domain/IReference";
import ArticlePage from "../../pages/ArticlePage";
import ArticlesPage from "../../pages/ArticlesPage";
import ChangePasswordPage from "../../pages/ChangePasswordPage";
import CitizenshipPage from "../../pages/CitizenshipPage";
import CitizenshipsPage from "../../pages/CitizenshipsPage";
import CountriesPage from "../../pages/CountriesPage";
import CountryPage from "../../pages/CountryPage";
import CreateArticlePage from "../../pages/CreateArticlePage";
import CreateCitizenshipPage from "../../pages/CreateCitizenshipPage";
import CreateCountryPage from "../../pages/CreateCountryPage";
import CreateDistrictPage from "../../pages/CreateDistrictPage";
import CreateEventTypePage from "../../pages/CreateEventTypePage";
import CreateHistoryPage from "../../pages/CreateHistoryPage";
import CreateNationalityPage from "../../pages/CreateNationalityPage";
import CreatePersonPage from "../../pages/CreatePersonPage";
import CreatePrisonCampCategoryPage from "../../pages/CreatePrisonCampCategoryPage";
import CreatePrisonCampPage from "../../pages/CreatePrisonCampPage";
import CreateProfessionPage from "../../pages/CreateProfessionPage";
import CreateReferencePage from "../../pages/CreateReferencePage";
import CreateRegionPage from "../../pages/CreateRegionPage";
import CreateRolePage from "../../pages/CreateRolePage";
import CreateUserPage from "../../pages/CreateUserPage";
import DashboardPage from "../../pages/DashboardPage";
import DistrictPage from "../../pages/DistrictPage";
import DistrictsPage from "../../pages/DistrictsPage";
import EventTypePage from "../../pages/EventTypePage";
import EventTypesPage from "../../pages/EventTypesPage";
import GenerateResetPasswordPage from "../../pages/GenerateResetPasswordPage";
import HistoriesPage from "../../pages/HistoriesPage";
import HistoryPage from "../../pages/HistoryPage";
import NationalitiesPage from "../../pages/NationalitiesPage";
import NationalityPage from "../../pages/NationalityPage";
import PersonPage from "../../pages/PersonPage";
import PersonsPage from "../../pages/PersonsPage";
import PrisonCampCategoriesPage from "../../pages/PrisonCampCategoriesPage";
import PrisonCampCategoryPage from "../../pages/PrisonCampCategoryPage";
import PrisonCampPage from "../../pages/PrisonCampPage";
import PrisonCampsPage from "../../pages/PrisonCampsPage";
import ProfessionPage from "../../pages/ProfessionPage";
import ProfessionsPage from "../../pages/ProfessionsPage";
import ReferencePage from "../../pages/ReferencePage";
import ReferencesPage from "../../pages/ReferencesPage";
import RegionPage from "../../pages/RegionPage";
import RegionsPage from "../../pages/RegionsPage";
import ResetPasswordPage from "../../pages/ResetPasswordPage";
import RolePage from "../../pages/RolePage";
import RolesPage from "../../pages/RolesPage";
import SignInPage from "../../pages/SignInPage";
import UsersPage from "../../pages/UsersPage";
import SettingsPage from "../../pages/SettingsPage";
import ExportToCsvPage from "../../pages/ExportToCsvPage";
import { AuthorizedRoute } from "./AuthorizedRoute";
import { ROUTES } from "./routes";
import CreateIndustryPage from "../../pages/CreateIndustryPage";
import IndustryPage from "../../pages/IndustryPage";
import IndustriesPage from "../../pages/IndustriesPage";
import CreateTeachingCategoryPage from "../../pages/CreateTeachingCategoryPage";
import TeachingCategoriesPage from "../../pages/TeachingCategoriesPage";
import TeachingCategoryPage from "../../pages/TeachingCategoryPage";
import TeachingModulePage from "../../pages/TeachingModulePage";
import CreateTeachingSubjectPage from "../../pages/CreateTeachingSubjectPage";
import TeachingSubjectPage from "../../pages/TeachingSubjectPage";
import TeachingSubjectsPage from "../../pages/TeachingSubjectsPage";
import CreateAssignmentPage from "../../pages/CreateAssignmentPage";
import AssignmentPage from "../../pages/AssignmentPage";
import AssignmentsPage from "../../pages/AssignmentsPage";
import EditAssignmentFormContainer from "../../components/Assignment/EditAssignment/EditAssignmentFormContainer"
import AssignmentReferences from "../../components/Assignment/EditAssignment/AssignmentReferences";
import CreateQuestionPage from "../../pages/CreateQuestionPage";
import QuestionPage from "../../pages/QuestionPage"
import QuestionsPage from "../../pages/QuestionsPage";
import EditQuestionFormContainer from "../../components/Question/EditQuestion/EditQuestionFormContainer";
import QuestionReferences from "../../components/Question/EditQuestion/QuestionReferences";


interface IPersonRouterProps {
  person: IPerson;
}

interface IPrisonCampRouterProps {
  prisonCamp: IPrisonCamp;
}

interface IAssignmentRouterProps {
  assignment: IAssignment
}

interface IQuestionRouterProps {
  question: IQuestion
}

interface IHistoryRouterProps {
  history: IHistory;
}

interface IReferenceRouterProps {
  reference: IReference;
}

const Router: React.FunctionComponent = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        {/* Authenticated routes */}

        <AuthorizedRoute exact path={ROUTES.AUTHORIZED.CHANGE_PASSWORD_ROUTE}>
          <Layout>
            <ChangePasswordPage />
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute exact path={ROUTES.ADMIN.USERS_ROUTE}>
          <Layout>
            <UsersPage />
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute exact path={ROUTES.ADMIN.USERS_ROUTE}>
          <Layout>
            <ExportToCsvPage />
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute exact path={ROUTES.ADMIN.USER_CREATE_ROUTE}>
          <Layout>
            <CreateUserPage />
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.AUTHORIZED.PERSON_CREATE_ROUTE}>
          <Layout>
            <CreatePersonPage></CreatePersonPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.AUTHORIZED.PERSON_ROUTE}>
          <Layout>
            <PersonPage></PersonPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.AUTHORIZED.PERSONS_ROUTE}>
          <Layout>
            <PersonsPage></PersonsPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.PRISON_CAMP_CREATE_ROUTE}>
          <Layout>
            <CreatePrisonCampPage></CreatePrisonCampPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.AUTHORIZED.PRISON_CAMP_ROUTE}>
          <Layout>
            <PrisonCampPage></PrisonCampPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.AUTHORIZED.PRISON_CAMPS_ROUTE}>
          <Layout>
            <PrisonCampsPage></PrisonCampsPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.HISTORY_CREATE_ROUTE}>
          <Layout>
            <CreateHistoryPage></CreateHistoryPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.AUTHORIZED.HISTORY_ROUTE}>
          <Layout>
            <HistoryPage></HistoryPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.AUTHORIZED.HISTORIES_ROUTE}>
          <Layout>
            <HistoriesPage></HistoriesPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.QUESTION_CREATE_ROUTE}>
          <Layout>
            <CreateQuestionPage></CreateQuestionPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.AUTHORIZED.QUESTION_ROUTE}>
          <Layout>
            <QuestionPage></QuestionPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.ASSIGNMENT_CREATE_ROUTE}>
          <Layout>
            <CreateAssignmentPage></CreateAssignmentPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.AUTHORIZED.ASSIGNMENT_ROUTE}>
          <Layout>
            <AssignmentPage></AssignmentPage>
          </Layout>
        </AuthorizedRoute>

        {/* <AuthorizedRoute path={ROUTES.ADMIN.ASSIGNMENTS_ROUTE}>
          <Layout>
            <AssignmentsPage></AssignmentsPage>
          </Layout>
        </AuthorizedRoute> */}

        <AuthorizedRoute path={ROUTES.ADMIN.TEACHING_SUBJECT_CREATE_ROUTE}>
          <Layout>
            <CreateTeachingSubjectPage></CreateTeachingSubjectPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.AUTHORIZED.TEACHING_SUBJECT_ROUTE}>
          <Layout>
            <TeachingSubjectPage></TeachingSubjectPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.AUTHORIZED.TEACHING_SUBJECTS_ROUTE}>
          <Layout>
            <TeachingSubjectsPage></TeachingSubjectsPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.TEACHING_CATEGORY_CREATE_ROUTE}>
          <Layout>
            <CreateTeachingCategoryPage></CreateTeachingCategoryPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.AUTHORIZED.TEACHING_CATEGORY_ROUTE}>
          <Layout>
            <TeachingCategoryPage></TeachingCategoryPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.AUTHORIZED.TEACHING_CATEGORIES_ROUTE}>
          <Layout>
            <TeachingCategoriesPage></TeachingCategoriesPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.TEACHING_MODULE_CREATE_ROUTE}>
          <Layout>
            <TeachingModulePage></TeachingModulePage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.AUTHORIZED.TEACHING_MODULE_ROUTE}>
          <Layout>
            <TeachingModulePage></TeachingModulePage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.CITIZENSHIP_CREATE_ROUTE}>
          <Layout>
            <CreateCitizenshipPage></CreateCitizenshipPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.CITIZENSHIP_ROUTE}>
          <Layout>
            <CitizenshipPage></CitizenshipPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.CITIZENSHIPS_ROUTE}>
          <Layout>
            <CitizenshipsPage></CitizenshipsPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.PROFESSION_CREATE_ROUTE}>
          <Layout>
            <CreateProfessionPage></CreateProfessionPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.PROFESSION_ROUTE}>
          <Layout>
            <ProfessionPage></ProfessionPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.PROFESSIONS_ROUTE}>
          <Layout>
            <ProfessionsPage></ProfessionsPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.INDUSTRY_CREATE_ROUTE}>
          <Layout>
            <CreateIndustryPage></CreateIndustryPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.INDUSTRY_ROUTE}>
          <Layout>
            <IndustryPage></IndustryPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.INDUSTRIES_ROUTE}>
          <Layout>
            <IndustriesPage></IndustriesPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.EVENT_TYPE_CREATE_ROUTE}>
          <Layout>
            <CreateEventTypePage></CreateEventTypePage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.EVENT_TYPE_ROUTE}>
          <Layout>
            <EventTypePage></EventTypePage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.EVENT_TYPES_ROUTE}>
          <Layout>
            <EventTypesPage></EventTypesPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.ROLE_CREATE_ROUTE}>
          <Layout>
            <CreateRolePage></CreateRolePage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.ROLE_ROUTE}>
          <Layout>
            <RolePage></RolePage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.ROLES_ROUTE}>
          <Layout>
            <RolesPage></RolesPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.NATIONALITY_CREATE_ROUTE}>
          <Layout>
            <CreateNationalityPage></CreateNationalityPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.NATIONALITY_ROUTE}>
          <Layout>
            <NationalityPage></NationalityPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.NATIONALITIES_ROUTE}>
          <Layout>
            <NationalitiesPage></NationalitiesPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.PRISON_CAMP_CATEGORY_CREATE_ROUTE}>
          <Layout>
            <CreatePrisonCampCategoryPage></CreatePrisonCampCategoryPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.PRISON_CAMP_CATEGORY_ROUTE}>
          <Layout>
            <PrisonCampCategoryPage></PrisonCampCategoryPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.PRISON_CAMP_CATEGORIES_ROUTE}>
          <Layout>
            <PrisonCampCategoriesPage></PrisonCampCategoriesPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.COUNTRY_CREATE_ROUTE}>
          <Layout>
            <CreateCountryPage></CreateCountryPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.COUNTRY_ROUTE}>
          <Layout>
            <CountryPage></CountryPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.COUNTRIES_ROUTE}>
          <Layout>
            <CountriesPage></CountriesPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.REGION_CREATE_ROUTE}>
          <Layout>
            <CreateRegionPage></CreateRegionPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.REGION_ROUTE}>
          <Layout>
            <RegionPage></RegionPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.REGIONS_ROUTE}>
          <Layout>
            <RegionsPage></RegionsPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.DISTRICT_CREATE_ROUTE}>
          <Layout>
            <CreateDistrictPage></CreateDistrictPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.DISTRICT_ROUTE}>
          <Layout>
            <DistrictPage></DistrictPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.DISTRICTS_ROUTE}>
          <Layout>
            <DistrictsPage></DistrictsPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.ARTICLE_CREATE_ROUTE}>
          <Layout>
            <CreateArticlePage></CreateArticlePage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.ARTICLE_ROUTE}>
          <Layout>
            <ArticlePage></ArticlePage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.ARTICLES_ROUTE}>
          <Layout>
            <ArticlesPage></ArticlesPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.AUTHORIZED.REFERENCE_CREATE_ROUTE}>
          <Layout>
            <CreateReferencePage></CreateReferencePage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.AUTHORIZED.REFERENCE_ROUTE}>
          <Layout>
            <ReferencePage></ReferencePage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.AUTHORIZED.REFERENCES_ROUTE}>
          <Layout>
            <ReferencesPage></ReferencesPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute exact path={ROUTES.AUTHORIZED.DASHBOARD_ROUTE}>
          <Layout>
            <DashboardPage></DashboardPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.SETTINGS_ROUTE}>
          <Layout>
            <SettingsPage></SettingsPage>
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.EXPORTTOCSV_ROUTE}>
          <Layout>
            <ExportToCsvPage></ExportToCsvPage>
          </Layout>
        </AuthorizedRoute>

        {/* Public routes */}

        <Route exact path={ROUTES.PUBLIC.SIGN_IN_ROUTE}>
          <LayoutPublic>
            <SignInPage />
          </LayoutPublic>
        </Route>

        <Route exact path={ROUTES.PUBLIC.GENERATE_RESET_PASSWORD_ROUTE}>
          <LayoutPublic>
            <GenerateResetPasswordPage />
          </LayoutPublic>
        </Route>

        <Route exact path={ROUTES.PUBLIC.RESET_PASSWORD_ROUTE}>
          <LayoutPublic>
            <ResetPasswordPage />
          </LayoutPublic>
        </Route>

        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export const PersonRouter: React.FunctionComponent<IPersonRouterProps> = (props) => {
  return (
    <Switch>
      <AuthorizedRoute exact path={ROUTES.AUTHORIZED.PERSON_REFERENCES_ROUTE}>
        <PersonReferences personReferences={props.person.references} personId={props.person.id}></PersonReferences>
      </AuthorizedRoute>

      <AuthorizedRoute exact path={ROUTES.AUTHORIZED.PERSON_CAPTIVITIES_ROUTE}>
        <Captivities captivities={props.person.captivities} events={props.person.events} personId={props.person.id}></Captivities>
      </AuthorizedRoute>

      <AuthorizedRoute exact path={ROUTES.AUTHORIZED.PERSON_EVENTS_ROUTE}>
        <Events events={props.person.events} personId={props.person.id}></Events>
      </AuthorizedRoute>

      <AuthorizedRoute path={ROUTES.AUTHORIZED.PERSON_ROUTE}>
        <EditPersonForm person={props.person}></EditPersonForm>
      </AuthorizedRoute>
    </Switch>
  );
};

export const PrisonCampRouter: React.FunctionComponent<IPrisonCampRouterProps> = (props) => {
  return (
    <Switch>
      <AuthorizedRoute exact path={ROUTES.AUTHORIZED.PRISON_CAMP_REFERENCES_ROUTE}>
        <PrisonCampReferences prisonCampReferences={props.prisonCamp.references} prisonCampId={props.prisonCamp.id}></PrisonCampReferences>
      </AuthorizedRoute>

      <AuthorizedRoute exact path={ROUTES.AUTHORIZED.PRISON_CAMP_PERSONS_ROUTE}>
        <PrisonCampPersons prisonCampId={props.prisonCamp.id}></PrisonCampPersons>
      </AuthorizedRoute>

      <AuthorizedRoute path={ROUTES.AUTHORIZED.PRISON_CAMP_ROUTE}>
        <EditPrisonCampForm prisonCamp={props.prisonCamp}></EditPrisonCampForm>
      </AuthorizedRoute>
    </Switch>
  );
};

export const AssignmentRouter: React.FunctionComponent<IAssignmentRouterProps> = (props) => {
  return (
    <Switch>
      <AuthorizedRoute exact path={ROUTES.AUTHORIZED.ASSIGNMENT_REFERENCES_ROUTE}>
        <AssignmentReferences assignmentReferences={props.assignment.references} assignmentId={props.assignment.id}></AssignmentReferences>
      </AuthorizedRoute>

      <AuthorizedRoute path={ROUTES.AUTHORIZED.ASSIGNMENT_ROUTE}>
        <EditAssignmentFormContainer assignment={props.assignment}></EditAssignmentFormContainer>
      </AuthorizedRoute>
    </Switch>
  );
};

export const QuestionRouter: React.FunctionComponent<IQuestionRouterProps> = (props) => {
  return (
    <Switch>
      <AuthorizedRoute exact path={ROUTES.AUTHORIZED.QUESTION_REFERENCES_ROUTE}>
        <QuestionReferences questionReferences={props.question.references} questionId={props.question.id}></QuestionReferences>
      </AuthorizedRoute>

      <AuthorizedRoute path={ROUTES.AUTHORIZED.QUESTION_ROUTE}>
        <EditQuestionFormContainer question={props.question}></EditQuestionFormContainer>
      </AuthorizedRoute>
    </Switch>
  );
};

export const HistoryRouter: React.FunctionComponent<IHistoryRouterProps> = (props) => {
  return (
    <Switch>
      <AuthorizedRoute exact path={ROUTES.AUTHORIZED.HISTORY_REFERENCES_ROUTE}>
        <HistoryReferences historyReferences={props.history.references} historyId={props.history.id}></HistoryReferences>
      </AuthorizedRoute>

      <AuthorizedRoute exact path={ROUTES.AUTHORIZED.HISTORY_PRISONCAMPS_ROUTE}>
        <HistoryPrisonCamps historyPrisonCamps={props.history.prisonCamps} historyId={props.history.id}></HistoryPrisonCamps>
      </AuthorizedRoute>

      <AuthorizedRoute exact path={ROUTES.AUTHORIZED.HISTORY_PERSONS_ROUTE}>
        <HistoryPersons historyPersons={props.history.persons} historyId={props.history.id}></HistoryPersons>
      </AuthorizedRoute>

      <AuthorizedRoute path={ROUTES.AUTHORIZED.HISTORY_ROUTE}>
        <EditHistoryForm history={props.history}></EditHistoryForm>
      </AuthorizedRoute>
    </Switch>
  );
};

export const ReferenceRouter: React.FunctionComponent<IReferenceRouterProps> = (props) => {
  return (
    <Switch>
      <AuthorizedRoute exact path={ROUTES.AUTHORIZED.REFERENCE_PERSONS_ROUTE}>
        <ReferencePersons referenceId={props.reference.id}></ReferencePersons>
      </AuthorizedRoute>

      <AuthorizedRoute exact path={ROUTES.AUTHORIZED.REFERENCE_PRISONCAMPS_ROUTE}>
        <PrisonCamps />
      </AuthorizedRoute>

      <AuthorizedRoute exact path={ROUTES.AUTHORIZED.REFERENCE_HISTORIES_ROUTE}>
        <Histories />
      </AuthorizedRoute>

      <AuthorizedRoute exact path={ROUTES.AUTHORIZED.REFERENCE_UNDERVISNING_ROUTE}>
        <Undervisning />
      </AuthorizedRoute>

      <AuthorizedRoute path={ROUTES.AUTHORIZED.REFERENCE_ROUTE}>
        <EditReferenceFormContainer reference={props.reference}></EditReferenceFormContainer>
      </AuthorizedRoute>
    </Switch>
  );
};

export default Router;
